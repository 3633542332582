// @see https://github.com/bluele/gforms

export default class Form {

    baseUrl;
    locale;
  
    /**
     * constructor
     *
     * @param {string} baseUrl
     * @param {string} locale
     */
    constructor(baseUrl, locale = 'de') {
      this.baseUrl = baseUrl;
      this.locale = locale;
    }
  
    /**
     *
     * @param {string} name
     * @param {{}} data
     * @returns {Promise<unknown>}
     */
    submit(name, data) {
      return new Promise((resolve, reject) => {
        const url = this.baseUrl + '/forms/' + name;
  
        fetch(new Request(url, {
          headers: new Headers({'content-type': 'application/json'}),
          body: JSON.stringify(data),
          method: 'POST',
        }))
            .then(res => {
              if (res.status === 422) {
                return res.json();
              }
  
              if (!res.ok || res.status > 201) {
                throw new Error('form service error');
              }
  
              return null;
            })
            .then(errors => {
              if (errors == null) {
                return null;
              }
  
              Object.keys(errors).forEach(name => {
                let msgs = errors[name].filter(msg => msg.lang === this.locale);
                if (msgs.length === 0) msgs = errors[name];
                errors[name] = msgs.map(msg => msg.text);
              })
  
              return errors;
            })
            .then(
                res => resolve(res),
                e => reject(e)
            );
      });
    }
  }