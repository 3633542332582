import React, { useState } from "react"

import { useFormik } from "formik"

import FormService from "./../../lib/service/form"
import { Link } from "gatsby"
import styles from "./request-form.module.css"
import Logo from "../../images/logo-digitalwert.svg"
import EnvelopImg from "../../images/img-formular-gesendet.svg"
const RequestForm = props => {
  const [submit, setSubmit] = useState(false)
  let formService = new FormService("https://forms.digitalwert.de", "de")

  const validate = values => {
    const errors = {}
    if (!values.forename) {
      errors.forename = "Diese Eingabe ist ein Pflichtfeld."
    } else if (values.forename.length > 15) {
      errors.forename = "Muss 15 Zeichen oder weniger"
    }

    if (!values.name) {
      errors.name = "Diese Eingabe ist ein Pflichtfeld."
    } else if (values.name.length > 20) {
      errors.name = "Muss 20 Zeichen oder weniger"
    }

    if (!values.email) {
      errors.email = "Diese Eingabe ist ein Pflichtfeld."
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Ungültige E-Mail-Adresse"
    }

    if (!values.message) {
      errors.message = "Diese Eingabe ist ein Pflichtfeld."
    }

    if (!values.privacy) {
      errors.privacy = "Dies ist ein Pflichtfeld."
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      forename: "",
      name: "",
      email: "",
      message: "",
      privacy: false,
      phone: "",
    },
    validate,
    onSubmit: values => {
      values.privacy = values.privacy ? 1 : 0
      if (
        !formik.errors.email &&
        !formik.errors.forename &&
        !formik.errors.name &&
        !formik.errors.message &&
        !formik.errors.privacy
      ) {
        setSubmit(!submit)
        formService
          .submit("Kontaktformular", values)
          .then(res => {

          })
          .catch(error => console.log(error))
      }
    },
  })

  function submitHandler() {
    // TODO
  }

  return (
    <div className={styles.sectionContainer}>
      <aside className={styles.digitalwert}>
        <Link to="/">
          <img src={Logo} alt="Digitalwert logo" />
        </Link>
        <button
          href={props.prevPath}
          className={`${styles.back} ${styles.mobileOn}`}
        >
          Zurück
        </button>
      </aside>
      <main className={styles.requestContainer}>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div style={!submit ? { display: "block" } : { display: "none" }}>
            <legend className={styles.title}>
              <h1>{props.headline}</h1>
            </legend>
            <div
              className={styles.formDesc}
              dangerouslySetInnerHTML={{
                __html: props.text,
              }}
            ></div>
            {/* <p className={styles.formDesc}>
              {props.text}
            </p> */}
            <p className={styles.contactData}>Kontaktdaten</p>
            <label htmlFor="forename">Vorname *</label>
            <input
              className={styles.textInput}
              style={
                formik.errors.forename ? { border: "1px solid #CC5D6A" } : {}
              }
              aria-label="forename"
              id="forename"
              name="forename"
              type="text"
              placeholder="Geben Sie bitte Ihren Vornamen ein."
              onChange={formik.handleChange}
              value={formik.values.forename}
              // onBlur={onNblurHandler}
              // onFocus={onNFocusHandler}
            />
            {formik.errors.forename ? (
              <div className={styles.error}>{formik.errors.forename}</div>
            ) : null}
            <label htmlFor="name">Nachname *</label>
            <input
              className={styles.textInput}
              style={formik.errors.name ? { border: "1px solid #CC5D6A" } : {}}
              aria-label="nachname"
              id="name"
              name="name"
              type="text"
              placeholder="Geben Sie bitte Ihren Nachnamen ein"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.errors.name ? (
              <div className={styles.error}>{formik.errors.name}</div>
            ) : null}
            <div className={styles.addressContainer}>
              <label htmlFor="email">E-Mail *</label>
              <input
                className={styles.textInput}
                style={
                  formik.errors.email ? { border: "1px solid #CC5D6A" } : {}
                }
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                aria-label="EMail"
                placeholder="Geben Sie bitte Ihre E-Mail Adresse ein"
              />
              {formik.errors.email ? (
                <div className={styles.error}>{formik.errors.email}</div>
              ) : null}
              <label htmlFor="phone">Telefon</label>
              <input
                className={styles.textInput}
                type="number"
                aria-label="phone"
                id="phone"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                placeholder="Geben Sie bitte Ihre Telefonnumer ein"
              />
            </div>
            <div className={styles.nachricht}>
              <label htmlFor="message">Nachricht *</label>
              <textarea
                style={
                  formik.errors.forename ? { border: "1px solid #CC5D6A" } : {}
                }
                aria-label="nachricht"
                name="message"
                id="message"
                type="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                placeholder="Geben Sie bitte Ihre Nachricht ein."
              ></textarea>
              {formik.errors.message ? (
                <div className={`${styles.error} ${styles.textareaError}`}>
                  {formik.errors.message}
                </div>
              ) : null}
            </div>
            <div>
              <label htmlFor="message" className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  aria-label="Datenschutz"
                  id="privacy"
                  className={styles.checkbox}
                  value={formik.values.privacy}
                  onChange={formik.handleChange}
                />
                <span>
                  Ja, ich habe die{" "}
                  <Link
                    to="https://www.digitalwert.de/datenschutz"
                    target={"_blank"}
                    className={styles.link}
                  >
                    Datenschutzerklärung{" "}
                  </Link>
                  gelesen und akzeptiere diese hiermit.
                </span>
              </label>
              {formik.errors.privacy ? (
                <div
                  className={`${styles.error} ${styles.textareaError}`}
                  style={{ marginTop: "1rem" }}
                >
                  {formik.errors.privacy}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={styles.requestSentContainer}
            style={submit ? { display: "block" } : { display: "none" }}
          >
            <img src={EnvelopImg} alt="envelope" />
            <p>Ihr Antrag wurde gesendet.</p>
            <div className={styles.requestSentButtonsContainer}>
              <Link to="/">
                <button
                  className={styles.weiter}>
                  Weiter<span></span>
                </button>
              </Link>
              <button className={styles.backToForm}>
                <span></span>FORMULAR ERNEUT AUSFÜLLEN
              </button>
            </div>
          </div>

          {!submit && (
            <div className={styles.buttons}>
              <div className={styles.buttonsFlex}>
                <Link to={props.prevPath}>
                  <button type="button" className={styles.back}>
                    Zurück
                  </button>
                </Link>
                <button
                  type="submit"
                  className={styles.send}
                  onClick={submitHandler}
                >
                  Absenden<span></span>
                </button>
              </div>
            </div>
          )}
        </form>
      </main>
    </div>
  )
}
export default RequestForm
