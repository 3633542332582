import React from "react"
import RequestForm from "../components/request-form/request-form"
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent";

const Contact = ({location, data}) => {
  return (
    <div>
      <CookieConsent locale="de" />
      <Helmet htmlAttributes={{ lang: "de" }}>
        <title>{data.datoCmsSeiten.seo.title}</title>
        <meta name="description" content={data.datoCmsSeiten.seo.description} />
      </Helmet>
      <RequestForm
       prevPath= {location.state ? location.state.prevPath : "/"}
       headline = {data.datoCmsSeiten.header[0].headline}
       text={data.datoCmsSeiten.header[0].text}
      />
    </div>
  )
}

export default Contact
export const query = graphql`
query contact {
    datoCmsSeiten(name: {eq: "Kontakt"}) {
      seo {
        description
        title
        twitterCard
      }
      header {
        ... on DatoCmsHeaderTextBild {
          id
          headline
          text
          softHeadline
        }
      }
    }
  }
  `
